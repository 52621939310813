<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" more-btn="高级搜索" :fold-search.sync="showFoldSearch" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="用信编码">
              <el-input v-model="searchForm.useCode" placeholder="请输入要搜索的用信编码" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="还款编号">
              <el-input v-model="searchForm.repaymentCode" placeholder="请输入要搜索的还款编号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="资金方">
              <el-input v-model="searchForm.capName" placeholder="请输入要搜索的资金方" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              <el-select v-model="searchForm.currentStatus" placeholder="请选择要搜索的状态" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in constants.currentStatus" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="12">
            <el-form-item label="还款日期">
              <ics-search-date :date.sync="createdArr" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="btn-inner">
          <el-button v-loading="loading.export" type="primary" @click="exportFile">
            导出
          </el-button>
        </template>
        <template slot="table-columns-before">
          <el-table-column label="序号" type="index" :index="indexMethod" />
        </template>
        <template slot="table-columns-after">
          <el-table-column fixed="right" label="操作" width="100">
            <template v-slot="scope">
              <el-link type="primary" class="text-btn" @click="payDetail(scope.row.repaymentInfo)">
                详情
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ics-table-inner>
    </div>
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import utils from '@/assets/js/utils'
export default {
  components: {},
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        useCode: '',
        repaymentCode: '',
        capName: '',
        currentStatus: '',
        repaymentDayStart: '',
        repaymentDayEnd: ''
      },
      columnOption: [
        { label: '还款记录编号', prop: 'repaymentInfo.repaymentCode', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '用信编号', prop: 'repaymentInfo.useCode', formatter: this.utils.isEffective, minWidth: 180 },
        { label: '资金方', prop: 'repaymentInfo.capName', formatter: this.utils.isEffective, minWidth: 180 },
        { label: '还款期限', prop: 'repaymentInfo.loanEnd', formatter: (row, col, cell) => this.utils.dateFormat(cell), minWidth: 180 },
        { label: '实际还款日期', prop: 'repaymentInfo.realRepaymentDay', formatter: this.utils.isEffective, minWidth: 180 },
        { label: '应还总额(元)', prop: 'repaymentInfo.shouldTotalAmount', formatter: this.utils.tableMoneyFormat, minWidth: 100 },
        { label: '已还总额(元)', prop: 'repaymentInfo.realTotalAmount', formatter: this.utils.tableMoneyFormat, minWidth: 100 },
        { label: '待还总额(元)', prop: 'repaymentInfo.currentTotalAmount', formatter: this.utils.tableMoneyFormat, minWidth: 100 },
        { label: '状态', prop: 'repaymentInfo.currentStatus', formatter: (row, col, cell) => this.utils.statusFormat(String(cell), 'currentStatus'), minWidth: 100 },
        { label: '还款方式', prop: 'repaymentInfo.ownFunds', formatter: (row, col, cell) => this.utils.statusFormat(cell, 'ownFunds'), minWidth: 140 },
      ]
    }
  },
  computed: {
    createdArr: utils.computedDate('repaymentDayStart', 'repaymentDayEnd')
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.payBack.pageList(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    payDetail (row) {
      if (row.ownFunds === 1) {
        if (['borrowing', 'firstRest'].includes(row.repaymentType)) {
          this.$router.push({ name: 'payOfflineSingleDetail', query: { repaymentCode: row.repaymentCode } })
        } else if (row.repaymentType === 'regular') {
          this.$router.push({ name: 'payOfflineManyDetail', query: { repaymentCode: row.repaymentCode } })
        } else {
          return false
        }
      } else if (row.ownFunds === 0) {
        if (['borrowing', 'firstRest'].includes(row.repaymentType)) {
          this.$router.push({ name: 'payOnLineSingleDetail', query: { repaymentCode: row.repaymentCode } })
        } else if (row.repaymentType === 'regular') {
          this.$router.push({ name: 'payOnLineManyDetail', query: { repaymentCode: row.repaymentCode } })
        } else {
          return false
        }
      } else {
        return false
      }
    },
    exportFile () {
      this.loading.export = true
      this.api.payBack.exportFile(this.searchForm).then(result => this.utils.exportFile(result)).finally(() => {
        this.loading.export = false
      })
    }
  }
}
</script>

<style>

</style>
